<script setup lang="ts">
import type { ComputedRef } from "vue";
import type { Notification } from "@shopware/composables";

defineEmits<{
  (e: "click:close", id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const colorCssMap = {
  info: "scheppach-primary",
  success: "scheppach-success",
  warning: "scheppach-warning",
  danger: "scheppach-error",
};

const iconsMap = {
  info: "info",
  success: "success",
  warning: "warning",
  danger: "error",
};

const themeTypeColor: ComputedRef<string> = computed(
  () => colorCssMap[props.notification.type] || "scheppach-primary",
);
const icon = computed(() => iconsMap[props.notification.type] || "info");
</script>

<template>
  <!-- don't remove; enforce unocss to include dynamically used classes:
  class="bg-scheppach-primary-50 bg-scheppach-success-50 bg-scheppach-warning-50 bg-scheppach-error-50"
  class="bg-scheppach-primary-700 bg-scheppach-success-700 bg-scheppach-warning-700 bg-scheppach-error-700"
  class="b-scheppach-primary-500 b-scheppach-success-500 b-scheppach-warning-500 b-scheppach-error-500"
  class="c-scheppach-primary-700 c-scheppach-success-700 c-scheppach-warning-700 c-scheppach-error-700"
  -->
  <div
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :data-testid="`notification-element-${notification.type}`"
    :class="`flex items-center justify-between b b-${themeTypeColor}-500 w-100vw xl:min-w-350px max-w-xs p-4 mb-4 rounded-lg shadow bg-${themeTypeColor}-50 text-${themeTypeColor}-700`"
    role="alert"
  >
    <div class="flex gap-3 items-center">
      <div
        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
      >
        <!-- class="i-sl-success i-sl-info i-sl-warning i-sl-error" -->
        <div :class="`text-7 c-${themeTypeColor}-700 i-sl-${icon}`"></div>
      </div>
      <div
        data-testid="notification-element-message"
        :class="`ml-3 text-sm font-normal c-${themeTypeColor}-700`"
      >
        {{ notification.message }}
      </div>
    </div>
    <button
      data-testid="notification-element-button"
      type="button"
      :class="`bg-transparent ml-5`"
      :data-dismiss-target="`toast-${notification.id}`"
      aria-label="Close notification"
      @click="$emit('click:close', notification.id)"
    >
      <span class="sr-only">{{ $t("close") }}</span>
      <div :class="`text-6 c-${themeTypeColor}-700 i-sl-close`"></div>
    </button>
  </div>
</template>
